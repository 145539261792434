import React, { FC, useRef } from "react";
import { Stage, Layer } from "react-konva";
import { ITemplate, ITemplateItem } from "dto/template.dto";
import TemplateItemGif from "./TemplateItemGif";
import TemplateItemText from "./TemplateItemText";
import TemplateItemImage from "./TemplateItemImage";
import TemplateStageBackground from "./TemplateStageBackground";

interface IProps {
  template: ITemplate;
  selectTemplateItem?: (templateItem: ITemplateItem) => void;
}

const TemplateStage: FC<IProps> = (props) => {
  const refStage = useRef<any>();

  return (
    <Stage
      ref={refStage}
      width={props.template?.width}
      height={props.template?.height}
    >
      <Layer>
        <TemplateStageBackground
          fill={props.template?.fill}
          width={props.template?.width} 
          height={props.template?.height}
        >
          {props.template?.template_items?.map((templateItem, key) => (
            templateItem?.type === "image/gif" ? (
              <TemplateItemGif 
                key={key}
                refStage={refStage}
                templateItem={templateItem} 
                selectTemplateItem={props.selectTemplateItem}
              />
            ) : templateItem?.type === "text/text" ? (
              <TemplateItemText 
                key={key}
                refStage={refStage}
                templateItem={templateItem}
                selectTemplateItem={props.selectTemplateItem}
              />
            ) : templateItem?.type === "image/png" ? (
              <TemplateItemImage 
                key={key}
                refStage={refStage}
                templateItem={templateItem}
                selectTemplateItem={props.selectTemplateItem}
              />
            ) : (
              <></>
            )
          ))}
        </TemplateStageBackground>
      </Layer>
    </Stage>
  )
}

export default TemplateStage;
